// import logo from './logo.svg';
import './App.css';

import YearlyChart from './components/YearlyChart';
import RegionChart from './components/RegionChart';
import ChronoTimeline from './components/pages/ChronoTimeline'
import CitiesChart from './components/CitiesChart';
import Navbar from './components/common/NavBar';
import Header from './components/common/Header';
import { Routes, Route, Link } from "react-router-dom";
import NoMatch from './components/pages/NoMatch';
import Chronobak from './components/pages/xChronobak';
import CityTimeline from './components/pages/CityTimeline';
import RegionTimeline from './components/pages/RegionTimeline';
import Presidents from './components/pages/Presidents';
import Aboutus from './components/pages/Aboutus';
import TerritoryStatus from './components/pages/TerritoryStatus';
import SupportUs from './components/pages/SupportUs';
import Home from './components/pages/Home';
function App() {
  return (
    <div className="App">
      <Navbar/>
      <Routes>
      <Route path='about' element={<RegionChart/>} />
        <Route path='about-us' element={<Aboutus/>} />
        <Route path='timeline2' element={<Chronobak/>} />
        <Route path='timeline' element={<ChronoTimeline/>} />
        <Route path='timeline/:region/:city' element={<CityTimeline/>} />
        <Route path='timeline/:region' element={<RegionTimeline/>} />
        <Route path='region' element={<RegionChart/>} />
        <Route path="cities" element={<CitiesChart />}></Route>
        <Route path="territorial-control" element={<TerritoryStatus />}></Route>
        <Route path="presidents" element={<Presidents />}></Route>
        <Route path='support-us' element={<SupportUs/>} />
        <Route path="/" element={(
          <>
          {/* <Helmet>
            <title>Amni Project</title>
          </Helmet> */}
          <Header />
          <Home />
          {/* {/* <YearlyChart /> */}
          {/* <RegionChart />
          <CitiesChart />  */}
          </>
        )}></Route>
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </div>
  );
}

export default App;
