import React, { useState } from 'react';
import { Pagination as BootstrapPagination } from 'react-bootstrap';

function Pagination({ totalPages, onPageChange, activePage, setActivePage }) {
//   const [activePage, setActivePage] = useState(1);

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    onPageChange(pageNumber);
  }

  return (
    <BootstrapPagination>
      <BootstrapPagination.Prev className="arrow" onClick={() => handlePageChange(activePage - 1)} disabled={activePage === 1} />
      <BootstrapPagination.Item onClick={() => handlePageChange(1)} active={activePage === 1}>
        1
      </BootstrapPagination.Item>
      <BootstrapPagination.Ellipsis disabled />
      <BootstrapPagination.Item onClick={() => handlePageChange(activePage - 1)} disabled={activePage === 1}>
        {activePage - 1}
      </BootstrapPagination.Item>
      <BootstrapPagination.Item active>{activePage}</BootstrapPagination.Item>
      <BootstrapPagination.Item onClick={() => handlePageChange(activePage + 1)} disabled={activePage === totalPages}>
        {activePage + 1}
      </BootstrapPagination.Item>
      <BootstrapPagination.Ellipsis disabled />
      <BootstrapPagination.Item onClick={() => handlePageChange(totalPages)} active={activePage === totalPages}>
        {totalPages}
      </BootstrapPagination.Item>
      <BootstrapPagination.Next className="arrow" onClick={() => handlePageChange(activePage + 1)} disabled={activePage === totalPages} />
    </BootstrapPagination>
  );
}

export default Pagination;
