import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {getMedia, formatDate, cityformat, casualties} from '../helpers/helpers'
import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';

import Card from 'react-bootstrap/Card';
import {Link} from 'react-router-dom'
function TodayInHistory({data}) {
    const [DataFinal, setDataFinal] = useState([]);

    const yearOrYears = (num) => {
        return num > 1 ? `${num} Years ago` : `${num} year ago`
    }
    console.log('years\n\n>>',data)
    if (!data || data.length < 1) {
        return null; // or return a message indicating no events to display
      }
return (
    <Container className=''>
      <h2>Today in History</h2>
      <Card  className="mb-3 history">
      {data.map((event) => (
        <>
          <span className='title-tags -tag'>{yearOrYears(event.years_ago)} today</span>
          {getMedia(event.source)}
          <Card.Body className='history'>
            <h4 className='vertical-timeline-element-subtitle -tag'>{event.title}</h4>
            {/* <Card.Text>{event.source[0].url}</Card.Text> */}
            
            {event.source.map((s, index) =>
          <p key={`links-${index}-${s.url ? s.url : s.link}`}>
            <Link target="_blank" style={{textAlign: 'left', marginBottom: '150px'}} className={`timeline-link`} to={s.url? s.url : s.link}>{s.title} {<OpenInNewIcon style={{ fontSize: 16 }}/>}
            </Link>
          </p>)}
          
          </Card.Body>
          </>
        
      )
      )}</Card>
    </Container>
  );
}

export default TodayInHistory;

//   useEffect(() => {
//     // Fetch the events from the API or your data source
//     const fetchEvents = async () => {
//       // Make your API call or fetch the data
//       const response = await fetch('/api/events/today');
//       const data = await response.json();

//       // Set the events in state
//       setEvents(data);
//     };

//     fetchEvents();
//   }, []);
// let events = [{id: 2, title: 'hello this is just a test my frieds', 
// description: 'this is also jsut a test and some nonsense i just types',
// year: 2012
// }]