import YouTubeVideo from '../common/YouTubeVideo'
import FacebookVideo from "../common/FacebookVideo";

export const getMedia = (source, id) => {
    const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=))([\w-]{10,12})(?:[^\s&]*)/;
    const facebookRegex = /(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:facebook\.com\/[^/]+\/videos\/)([0-9]+)(?:[^\s&]*)/; // /facebook\.com\/[^/]+\/videos\/([0-9]+)/;
    const embedUrls = [];
    const facebookVid = [];

    source.some((o) => {
      if (o.hasOwnProperty('type') && (youtubeRegex.test(o.link) || youtubeRegex.test(o.url))) {
        const videoId = o.link ? o.link.match(youtubeRegex)[1] : o.url.match(youtubeRegex)[1];
        const embedUrl = `https://www.youtube.com/embed/${videoId}`;
        embedUrls.push(videoId);
        return true;
      } else if (o.hasOwnProperty('type') && (o.link && (o.link.includes("facebook")) & (o.link && o.link.includes("video"))) || (o.url && (o.url.includes("facebook")) & (o.url && o.url.includes("video")))) {
          facebookVid.push(o.link ? o.link : o.url);
          return true;
      }
      return false;
  });

    // source.some((o) => {
    //   if (!o.hasOwnProperty('type')) {
    //     return false;
    //   } else {
    //     if (o.link && (o.link.includes("facebook") && o.link.includes("video"))){facebookVid.push(o.link);}
    //   }
    //   return false;
    // });
  
    if (embedUrls.length > 0) {
      return <YouTubeVideo videoId={embedUrls[0]} key={embedUrls[0]} />
    } 
    if (facebookVid.length > 0) {
      const encodedUrl = encodeURIComponent(facebookVid[0]).replace(/%2F/g, '/');
      console.log(facebookVid, encodedUrl)
      return <FacebookVideo url={encodedUrl} key={id} />
    }
    return null;
};



export const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const formattedDateString = date.toLocaleDateString('en-US', options).replace(',', '');
    return formattedDateString;
  };

export const cityformat = (obj) => {
let wounded = obj.wounded || 0;
let killed = obj.dead || 0;
let city = obj.city.name;

// let message = `${city} `;
let message = ``;

    if (killed > 0) {
        message += `${killed} killed `;
    }
    if (wounded > 0) {
        message += `${wounded} injured `;
    }
    if (wounded === 0 && killed === 0) {
        message += '';
    }
    return message
};

export const casualties = ({dead, injured}) => {
  let wounded = injured || 0;
  let killed = dead || 0;
  
      if (killed > 0) {
        return `${killed} killed `;
      }
      if (wounded > 0) {
        return `${wounded} injured `;
      }
      return ''; 
  };