import React from 'react'
import { Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
const Header = () => {
  return (
    <div>
      <Container fluid>
      <Row>
        <Col
          className="d-flex justify-content-center align-items-center heading" id='heading'
          // style={{ backgroundImage: 'url("https://war.ukraine.ua/wp-content/uploads/2022/03/war_map.png")', height: '300px' }}
        >
          <h1 className="text-center heading-h1">Amni is Security</h1>
        </Col>
      </Row>
    </Container>
    </div>
  )
}

export default Header