import React from 'react'
import sad from './sad.png';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
const NoMatch = () => {
  return (
    <div>
        <h1>404</h1>
        {/* <SentimentVeryDissatisfiedIcon sx={{ fontSize: 385 }}/> */}
        <img className='sad404' src={sad}/>
    </div>
  )
}

export default NoMatch