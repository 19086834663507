import React from 'react';

const YoutubeVideo = ({ videoId }) => {
    let bt = `https://www.youtube.com/embed/${videoId}`
    //console.log(videoId, bt)
  return (
    <div className='video-iframe'>
    <iframe 
    width="100%"
    height="100%"
    src={`https://www.youtube.com/embed/${videoId}`}
    title="YouTube video player" 
    frameBorder="0" 
    allow={`accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;`}></iframe>
  </div>
  )
}

export default YoutubeVideo

