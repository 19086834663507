import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';

function NavBar() {
  const [expanded, setExpanded] = useState(false);

  const toggleNavbar = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Navbar bg="dark" variant="dark" className="nvbar" expand="lg">
        <Container>
          <Navbar.Brand href="/">Amni Project</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-nav" onClick={toggleNavbar} />
          <Navbar.Collapse id="navbar-nav" className={expanded ? 'show' : ''}>
            <Nav className="me-auto">
              <Nav.Link href="/timeline">Timeline</Nav.Link>
              <Nav.Link href="/presidents">Presidents</Nav.Link>
              <Nav.Link href="/territorial-control">Territories</Nav.Link>
              <NavDropdown title="Info" id="about-support-dropdown">
                <NavDropdown.Item href="/about-us">About Us</NavDropdown.Item>
                <NavDropdown.Item href="/support-us">Support Us</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;
