// import React, { useEffect, useState, useRef } from 'react'
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
// import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
// import 'react-vertical-timeline-component/style.min.css';
// import '../../timeline-component-style.css'
// import axios from "axios";
// import FacebookVideo from '../common/FacebookVideo';
// import YouTubeVideo from '../common/YouTubeVideo'
// import {getMedia, formatDate, cityformat} from '../helpers/helpers'


// import OpenInNewIcon from '@mui/icons-material/OpenInNew';
// import ExpandCircleDownRoundedIcon from '@mui/icons-material/ExpandCircleDownRounded';
// import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
// import WorkIcon from '@mui/icons-material/Work';
// // import SchoolIcon from '@mui/icons-material/School';
// // import StarIcon from '@mui/icons-material/Star';
// import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
// import ArrowCircleDownRoundedIcon from '@mui/icons-material/ArrowCircleDownRounded';
// import { borderBottom, margin } from '@mui/system';



// const ChronoTimeline = () => {
//   const [loading, setLoading] = useState(true)
//   const [data, setData] = useState([]);
//   const [pagination, setPagination] = useState({});
//   const [currentPage, setCurrentPage] = useState(1);

//   useEffect(() => {
//     setLoading(true)
//     let url = `http://localhost:8000/api/timeline/?page=${currentPage}`
//     axios
//       .get(url)
//       .then((response) => {
//           setData([...data, ...response.data.data])
//           setPagination(response.data.pagination)
//           setLoading(false) 
//       })
//       .catch((error) => console.log(error.message))
//   }, [currentPage]);

//   if (!data) {
//       return <div>Loading...</div>;
//     }

//   function handlePreviousPage() {
//     setCurrentPage(pagination.previous_page_number);
//   }
//   const handleDash = (name) => {
//     return name.toLowerCase().replace(' ', '-') 
//   }
//   function handleNextPage() {
//       setCurrentPage(pagination.next_page_number);
//   }

//   return (
//     <div className='timeline-container-div'>
//       <h1 className="timelineheader">Timeline: Somalia</h1>

//       <VerticalTimeline>
//       {data.map(obj => {return(
//         <VerticalTimelineElement key={obj.id}
//         className="vertical-timeline-element--work"
//         contentStyle={{ background: 'ffffff' , color: 'rgb(33, 150, 243)' }}
//         contentArrowStyle={{ borderRight: '7px solid  white' }}
//         date={<div>{formatDate(obj.eventDate)}</div>} 
//         iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
//         icon={<WorkIcon />}
//         >
//         <h3 className="vertical-timeline-element-title city-and-count">
//           <Link to={`/timeline/${handleDash(obj.city.region.name)}/${handleDash(obj.city.name)}`}><span className='title-tags'>{obj.city.name}</span></Link>
//           <span className='title-tags'>{obj.city.region.name}</span>
//           {cityformat(obj) && <span className='title-tags'>{cityformat(obj)}</span>}
//           {getMedia(obj.source, obj.id) ?? ''}</h3>        
//         <h4 className="vertical-timeline-element-subtitle">{obj.title}</h4>
//         {obj.source.map(s =>
//           <p key={s.url ? s.url : s.link}>
//             <Link target="_blank" style={{textAlign: 'left', marginBottom: '150px'}} className={'timeline-link'} to={s.url? s.url : s.link}>{s.title} {<OpenInNewIcon style={{ fontSize: 16 }}/>}
//             </Link>
//           </p>
//         )}
//       </VerticalTimelineElement>)
//         })}
//       </VerticalTimeline>

//       <div className='load-more'>
//       {pagination.has_next && (
//         <span className='timeline-load-more' onClick={()=> setCurrentPage(pagination.next_page_number)}>Load More...</span>
//         // <ArrowCircleDownRoundedIcon className='pagedown' onClick={()=> setCurrentPage(pagination.next_page_number)} style={{ fontSize: 70, color:'#2096F3' }}/>
//       )}
//       </div>
//     </div>
//   )
// }
// export default ChronoTimeline

import React, {useState} from 'react'
import { useParams } from 'react-router-dom';
import DynamicTimeline from '../common/DynamicTimeline';

const ChronoTimeline = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const { city, region } = useParams();
    let sub = () =>{
      return <div className='color-coding'><span className='occupied'>Chronological timeline with clickable cities and regions that link to their respective timelines.</span></div>
    }
  return (
    <div>
        {/* CityTimeline {region} {city} */}
        <DynamicTimeline title={`Al-Shabaab`} url={``} subtitle={()=>''} />
    </div>
  )
}

export default ChronoTimeline