import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import { api } from '../../utils'
import YearlyChart from '../common/YearlyChart';
import RegionChart from '../common/RegionChart';
import CitiesChart from '../common/CitiesChart';
import TodayInHistory from '../common/TodayInHistory';
import Loading from '../common/Loading';

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [yearlyData, setYearlyData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [historyData, setHistoryData] = useState([])

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);

      const [yearlyResponse, regionResponse, citiesResponse, historyResponse] = await Promise.all([
        api.get('/api/yearly/'),
        api.get('/api/summaryregion/'),
        api.get('/api/summary/'),
        api.get('/api/today-in-history'),
      ]);

      setYearlyData(yearlyResponse.data);
      setRegionData(regionResponse.data.data);
      setCitiesData(citiesResponse.data.data);
      setHistoryData(historyResponse.data.data)
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };
  if (loading) {
    return <Loading />
  }
//   if (regionData) {
//     console.log('redd',regionData)
//   }
//   if (historyData) {
//     console.log('history',historyData)
//   }
  return (
    <HelmetProvider>
    <div className='homepage'>
    
      <Helmet>
        <title>Amni Project: homepage</title>
      </Helmet>
      {/* Pass the data and loading state as props to each child component */}
      <TodayInHistory data={historyData} />
      <YearlyChart data={yearlyData} loading={loading} />
      <RegionChart data={regionData} loading={loading} />
      <CitiesChart data={citiesData} loading={loading} /> 
      <div className='brief container'>
        <p>Our primary focus is on gathering, analyzing, and distributing accurate and comprehensive conflict data. By providing reliable information, we aim to shed light on the realities of conflicts and their impact on communities. We understand that transparency is a cornerstone of peacebuilding, enabling informed decision-making and fostering trust among stakeholders.</p>
        <p>Moreover, we emphasize the importance of accessibility in our work. We strive to break down barriers that hinder access to critical information and resources. Through user-friendly platforms, open data initiatives, and collaborative partnerships, we empower individuals, organizations, and policymakers to actively engage in peacebuilding efforts.</p>
      </div>
      {/* Display loading state */}
      {/* {loading ? <Loading /> : null} */}
    </div>
    </HelmetProvider>
  );
};

export default Home;
