import React from 'react'

const FacebookVideo = ({url}) => {
  return (
    <div className='video-iframe'>
        <iframe 
            src={`https://www.facebook.com/plugins/video.php?height=314&href=${url}&show_text=false&width=560&t=0`}
            width="100%" 
            height="100%" 
            // style="border:none;overflow:hidden" 
            scrolling="no" frameBorder="0" 
            // allowfullscreen="true" 
            allow="autoplay; clipboard-write; 
            encrypted-media; picture-in-picture;" 
            allowFullScreen={true}>
        </iframe>
    </div>
  )
}

export default FacebookVideo
