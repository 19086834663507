import React from 'react'
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import DotLoader from 'react-spinners/DotLoader'
const Loading = () => {
  return (
    <div>
        <DotLoader size={200} color={'#2196F3'} className="container"/>
    </div>
  )
}

export default Loading