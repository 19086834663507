import React, { useEffect, useState } from 'react'
import axios from "axios";
// import { api } from '../utils'
import { Chart } from "react-google-charts";

const CitiesChart = ({data, loading}) => {

    const [DataFinal, setDataFinal] = useState([]);
    // const [data, setData] = useState(['mon', 'tue', 'wed']);
    
    useEffect(() => {
        if (data) {

            const transformedData = data.map(({ name, dead, wounded }) => [
                name,
                dead, 
                wounded,
              ]);
      
          setDataFinal(transformedData);
        }
        //   setDataFinal(data.map(y => [y.name, y.dead, y.wounded]));
      }, [data]);
      

    if (!data) {
        return '';
      }

    console.log('google-data =>',data)
    if (data) {
        // console.log('gg', data.map(d => [d.year, d.killed, d.injured]))
        // console.log('sfasfa',[["Age", "Weight"], ...data])
        console.log('trasnformed', DataFinal)
    }
    const row = data.filter(d => d.dead > 50).sort((a, b) => b.dead - a.dead).map(y => [y.name, y.dead, y.wounded])
    console.log('rowzz', row)
    const columns = [
        {
          type: 'string',
          label: 'Name'
        },
        {
          type: 'number',
          label: 'killed'
        },
        {
          type: 'number',
          label: 'wounded'
        }
      ];
    console.log('roww',row)
    const options = {
      title: 'Cities',
    //   pieSliceText: 'label',
    //   slices: {  0: {offset: 0.2},
    //             7: {offset: 0.2},
    //             8: {offset: 0.2},
    //   },
      colors: ['#DC3912', '#3366CC', '#FF9900', '#109618'],
        // other options
      };
  return (
    <div>

    <Chart
        chartType="PieChart"
        data={[columns, ...row]}
        options={options}
        width="100%"
        height="400px"
        legendToggle
  />

  </div>
  )
}

export default CitiesChart