import React from 'react'
import { Container } from 'react-bootstrap';
// import {Helmet} from 'react-helmet'

const Aboutus = () => {
  return (
    <div>
      {/* <Helmet>
        <title>About us</title>
      </Helmet> */}
      <Container className='about-us' style={{ fontSize: '20px' }}>
        <p>Amni project is a non-partisan organization that is dedicated to documenting and archiving the ongoing and past conflicts in East Africa.</p>
        <p>
          Our primary goal is to collect and organize data on conflicts in the region for archival purposes. We aim to provide comprehensive and accurate information on the events that have taken place in the region, ranging from the causes of the conflict to the number of people affected, in order to aid in future research and decision-making.
        </p>
        <p>Additionally, we use this data to create interactive visual representations of the conflicts in the form of charts, maps, and timelines, allowing for a deeper understanding and analysis of the complex dynamics at play.</p>
        <p>We strive to promote transparency and accessibility of conflict data, and we are committed to maintaining our independence and impartiality in all our work.</p>
      </Container>
        
    </div>
  )
}

export default Aboutus