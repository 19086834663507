import React, {useState} from 'react'
import { useParams } from 'react-router-dom';
import DynamicTimeline from '../common/DynamicTimeline';

const RegionTimeline = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const { city, region } = useParams();
    
  return (
    <div>
        {/* CityTimeline {region} */}
        <DynamicTimeline title={region} url={`&region=${region}`} subtitle={()=>''}/>
    </div>
  )
}

export default RegionTimeline