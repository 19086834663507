import React, { useEffect, useState } from 'react'
import axios from "axios";
import { api } from '../utils'
import { Chart } from "react-google-charts";

const RegionChart = () => {
    const [data, setData] = useState();
    const [DataFinal, setDataFinal] = useState([]);
    // const [data, setData] = useState(['mon', 'tue', 'wed']);
    
    let url = `/api/summaryregion/`
    useEffect(() => {
      api
        .get(url)
        .then((response) => {
            setData(response.data.data)
            console.log('newss',response.data.data)
            const transformedData = data.map(({ year, killed, injured }) => [
                year,
                killed, 
                injured,
              ]);

              setDataFinal(data.map(y => [y.year, y.killed, y.injured]));
              
        })
        .catch((error) => console.log(error.message))
        // .finally(() => setLoaded(true));
    }, [url]);

    if (!data) {
        return <div>Loading...</div>;
      }

    console.log('google-data =>',data)
    if (data) {
        // console.log('gg', data.map(d => [d.year, d.killed, d.injured]))
        // console.log('sfasfa',[["Age", "Weight"], ...data])
        console.log('trasnformed', DataFinal)
    }
    const dummy = data.map(obj => {return{name: obj.name, killed: obj.killed, injured: obj.wounded}})
    console.log('------dummy', dummy)
    const row = data.sort((a, b) => b.killed - a.killed).map(obj => [obj.name, obj.killed, obj.wounded])
    console.log('rowzz', row)
    const columns = [
        {
          type: 'string',
          label: 'Name'
        },
        {
          type: 'number',
          label: 'killed'
        },
        {
          type: 'number',
          label: 'wounded'
        }
      ];
    console.log('roww',row)
    const options = {
      title: 'Regions',
    //   pieSliceText: 'label',
    //   slices: {  0: {offset: 0.2},
    //             7: {offset: 0.2},
    //             8: {offset: 0.2},
    //   },
      colors: ['#DC3912', '#3366CC', '#FF9900', '#109618'],
        // other options
      };
  return (
    <div>
        {data ? 
        
    
    <Chart
        chartType="PieChart"
        data={[columns, ...row]}
        options={options}
        width="100%"
        height="400px"
        legendToggle
  />

  
  : null}
  </div>
  )
}

export default RegionChart