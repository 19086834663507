import React from 'react'

const SupportUs = () => {
  return (
    <div className='container mt-3 mb-30'>
        <p>Thank you for considering supporting the Amni project! Your contribution is essential in helping us continue our mission of documenting and archiving conflicts in East Africa. As a non-profit organization, we rely on the generosity of individuals like you to sustain and enhance our work.
            Here are some ways you can support us:
        </p>
        <p className='mt-30'>1. Make a Donation: Your financial contribution can make a significant impact on our ability to collect, organize, and maintain comprehensive conflict data. Your donation will go towards acquiring and maintaining servers, NAS (Network-Attached Storage) devices, and additional servers for data redundancy. These resources are crucial for storing and processing the vast amount of data we collect. Every donation, no matter the size, is greatly appreciated and goes directly towards supporting our operations.</p>
        <p>2. Spread the Word: Help us raise awareness about the Amni project and our goals by sharing our website, social media accounts, and publications with your friends, family, and colleagues. By spreading the word, you can help us reach a wider audience and potentially attract more support and collaboration.</p>
        <p>3. Volunteer Your Skills: If you have relevant skills and expertise in data management, web development, graphic design, or content creation, consider volunteering your time and knowledge to support our work. We value the contributions of passionate individuals who can help us improve our website, create engaging visualizations, and enhance user experience.</p>
        <p>4. Collaborate with Us: If you represent an organization, academic institution, or research entity, we are open to collaborations and partnerships. By working together, we can leverage our collective knowledge and resources to enhance the quality and impact of our data collection and analysis efforts.</p>
        <p>5. Provide Feedback: We value feedback from our users and the broader community. If you have suggestions, ideas, or insights that can help us improve our platform, data visualization techniques, or overall user experience, please don't hesitate to reach out to us. Your input is invaluable in shaping our future developments.</p>
        <p>
        Your support will directly contribute to the availability and accessibility of comprehensive conflict data in East Africa, aiding researchers, policymakers, and peacebuilding efforts in the region. Together, we can make a difference in promoting understanding, peace, and stability.
        To make a donation or explore other ways to support us, please visit our Support Us page on our website. We extend our heartfelt gratitude for your support and dedication to our mission.
        </p>
    </div>
  )
}

export default SupportUs