import { width } from "@mui/system";
import React, { useEffect, useState } from 'react'
import { Card, Row, Col } from "react-bootstrap";
import { Helmet, HelmetProvider } from 'react-helmet-async';
// import {Helmet} from "react-helmet";
import axios from "axios";
import { api } from '../../utils'
import {getMedia, formatDate, cityformat, casualties} from '../helpers/helpers' 
import Loading from "../common/Loading";

const Presidents = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        setLoading(true)
        // let url = `${url}`
        api
          .get(`/api/terms/`)
          .then((response) => {
            // console.log(response.data)
              setData(response.data)
              setLoading(false) 
          })
          .catch((error) => console.log(error.message))
      }, []);
    
    // if (!data || loading) {
    //     return ''
    //     // return <div>Loading...</div>;
    // }
    // if (!loading) {
    //     // console.log(data.map(president => president.name))
    //     }
    if (loading) {
        return <Loading/>;
        // return ''
        }
    const imageName = (name) => {
        const fileName = name.replace(/ /g, '_').toLowerCase() + '.jpeg';
        return require(`../../presidents/${fileName}`);
        }
    return (
        <HelmetProvider>
        <Helmet>
        <title>Somali Presidents</title>
      </Helmet>

        <Row className="presdents-row">
            {/* <Helmet>
                <title>Presidents Timeline - Somalia</title>
            </Helmet> */}
            {data.filter(president => president.events.total_dead !== null).map((president, index) => (
                <Col xs={12} md={6} lg={4} key={index}>
                <Card className="m-2 presidents-card">
                    <Card.Img variant="top" src={imageName(president.name)} alt='j' />
                    <Card.Body>
                    <Card.Title>{president.name}</Card.Title>
                    <Card.Text>
                        {formatDate(president.start_term)} -- {president.end_term? formatDate(president.end_term) : 'incumbent'}
                    </Card.Text>
                    <Card.Text>
                        {president.events.total_dead && <span className="title-tags" style={{ marginRight: '10px' }}>{president.events.total_dead} killed</span> }<span></span>
                        {president.events.total_wounded && <span className="title-tags">{president.events.total_wounded} injured</span> }
                    </Card.Text>
                    <Card.Text>
                        {president.events.pending && <span className="title-tags pening-data">{president.events.pending} events pending review</span> }
                    </Card.Text>
                    <Card.Text>
                        {president.events.missingData && <span className="missing-data" style={{ marginRight: '10px' }}>Missing data:<br/>{president.events.missingData}</span> }<span></span>
                        {/* {president.events.pending && <span className="title-tags">{president.events.pending} injured</span> } */}
                    </Card.Text>
                    </Card.Body>
                </Card>
                </Col>
            
            ))}
        </Row>
        <div className="presidents-disclosure">
        The total number of individuals killed or injured during a president's term is not definitive and is subject to revision due to ongoing reviews and potential gaps in the collected data. To ensure transparency, we have disclosed any existing data gaps and the number of events currently under review. We strive to provide the most accurate and comprehensive information available, acknowledging the dynamic nature of data collection and analysis. Our commitment to transparency enables us to continuously update and refine our records, ensuring the highest level of accuracy and accountability.
        </div>
        </HelmetProvider>
    );
};

export default Presidents;
