import axios from 'axios';

const getBaseUrl = () => {
  let url;
  switch(process.env.NODE_ENV) {
    case 'production':
      url = '';
      break;
    case 'development':
    default:
      url = 'http://localhost:8000';
  }

  return url;
}

export const api = axios.create({
  baseURL: getBaseUrl(),
});
