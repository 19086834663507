import React, {useState} from 'react'
import { useParams } from 'react-router-dom';
import DynamicTimeline from '../common/DynamicTimeline';

const CityTimeline = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const { city, region } = useParams();
    
  return (
    <div>
        {/* CityTimeline {region} {city} */}
        <DynamicTimeline title={`${city} ${region}`} url={`&region=${region}&city=${city}`} subtitle={()=>''} />
    </div>
  )
}

export default CityTimeline