import React, { useEffect, useState, useRef } from 'react'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import '../../timeline-component-style.css'
import axios from "axios";
import FacebookVideo from '../common/FacebookVideo';
import YouTubeVideo from '../common/YouTubeVideo'
import {getMedia, formatDate, cityformat, casualties} from '../helpers/helpers'
import PG from './Pagination';
import { Helmet, HelmetProvider } from 'react-helmet-async'; 

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ExpandCircleDownRoundedIcon from '@mui/icons-material/ExpandCircleDownRounded';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import WorkIcon from '@mui/icons-material/Work';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import ArrowCircleDownRoundedIcon from '@mui/icons-material/ArrowCircleDownRounded';
import { borderBottom, margin } from '@mui/system';
import { api } from '../../utils'
import Loading from './Loading';



const DynamicTimeline = ({ url, title, subtitle }) => {

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState('');
  const [pagination, setPagination] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setLoading(true)
    api
      .get(`/api/timeline/?page=${currentPage}${url ? url : ''}`)
      .then((response) => {
        // console.log(`http://localhost:8000/api/timeline/?page=${currentPage}${url}`)
          setData(response.data.data)
          window.scrollTo({ top: 0, behavior: 'instant' });
          setPagination(response.data.pagination)
          document.querySelector("body").scrollTo({ top: 0, behavior: 'instant' });
          setLoading(false) 
          
      })
      .catch((error) => console.log(error.message))
  }, [currentPage]);



  if (!data) {
      return <Loading/>;
      // return ''
    }
  function handlePreviousPage() {
    setCurrentPage(pagination.previous_page_number);
  }

  function handleNextPage() {
      setCurrentPage(pagination.next_page_number);
  }
  const handleDash = (name) => {
    return name.toLowerCase().replace(' ', '-') 
  }
  
  const handleTitle = (title) => {
    return title.replace('-', ' ').replace(/\b\w/g, (match) => match.toUpperCase())
  }
  function getSTerritorytatus(obj) {
    if (obj && obj.status) {
      if (obj.status === 'lost') {
        return `#ED753E`;
      } else if (obj.status === 'liberated') {
        return '#4CB1CD';
      }
    }
    return 'ffff';
  }
  function lostOrliberated(obj) {
    if (obj && obj.status) {
      if (obj.status === 'lost') {
        return `Occupied`;
      } else if (obj.status === 'liberated') {
        return 'Liberated';
      }
    }
    return '';
  }
  return (
    <HelmetProvider>
    <div className='timeline-container-div'>
      <Helmet>
        <title>Timeline: {title}</title>
      </Helmet>
      
      <h1 className="timelineheader">Timeline: {handleTitle(title)}</h1>
      {subtitle()}
      {/* <Loading /> */}
      <VerticalTimeline passive={true}>
      {data.map((obj, index) => {return(
        <VerticalTimelineElement key={`timeline-${index}-${obj.id}`}
        className={`vertical-timeline-element--work`}
        contentStyle={{ background: getSTerritorytatus(obj), color: 'rgb(33, 150, 243)' }} 
        
        contentArrowStyle={{ borderRight: `7px solid ${getSTerritorytatus(obj)}` }}

        date={<div>{obj.date ? obj.date : formatDate(obj.eventDate)}</div>} 
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        icon={<AutoStoriesIcon />}
        >
        <h3 className="vertical-timeline-element-title city-and-count tags-container">
        <span className={`title-tags ${lostOrliberated(obj)}-tag`}><Link to={`/timeline/${handleDash(obj.city.region.name)}/${handleDash(obj.city.name)}`}>{obj.city.name}</Link></span>
          <span className='title-tags'>
            <Link to={`/timeline/${handleDash(obj.city.region.name)}`}>{obj.city.region.name}</Link>
            </span>
            {casualties({dead: obj.dead}) && <span className='title-tags'>{casualties({dead: obj.dead})}</span>}
            {casualties({injured: obj.wounded}) && <span className='title-tags'>{casualties({injured: obj.wounded})}</span>}
            {lostOrliberated(obj) && <span className={`title-tags ${lostOrliberated(obj)}`}>{lostOrliberated(obj)}</span>}
          </h3>{getMedia(obj.source, obj.id) ?? ''}       
        <h4 className={`vertical-timeline-element-subtitle ${lostOrliberated(obj)}-tag `}>{obj.title}</h4>
        {obj.source.map((s, index) =>
          <p key={`links-${index}-${s.url ? s.url : s.link}`}>
            <Link target="_blank" style={{textAlign: 'left', marginBottom: '150px'}} className={`timeline-link ${lostOrliberated(obj)}-tag`} to={s.url? s.url : s.link}>{s.title} {<OpenInNewIcon style={{ fontSize: 16 }}/>}
            </Link>
          </p>
        )}
      </VerticalTimelineElement>)
        })}
      </VerticalTimeline>
      <div className='timeline-pagination'>
      <PG activePage={currentPage} setActivePage={setCurrentPage} totalPages={pagination.all_pages} onPageChange={setCurrentPage}/>
      </div>
    </div>
    </HelmetProvider>
  )
}

export default DynamicTimeline