// import React, { useEffect, useState } from 'react'
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
// import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
// import axios from "axios";
// import { Chrono} from "react-chrono";
// import OpenInNewIcon from '@mui/icons-material/OpenInNew';
// import { createElement } from 'react';
// // import parse from 'html-react-parser';
// import Test from './Test';


// const Chronobak = () => {
//   const [loading, setLoading] = useState(true)
//   const [data, setData] = useState('');
//   const [pagination, setPagination] = useState({});
//   const [currentPage, setCurrentPage] = useState(50);




//   useEffect(() => {
//     setLoading(true)
//     let url = `http://localhost:8000/api/timeline/?page=${currentPage}`
//     axios
//       .get(url)
//       .then((response) => {
//           setData([...data, ...response.data.data])
//           setPagination(response.data.pagination)
//           setLoading(false) 
//       })
//       .catch((error) => console.log(error.message))
//   }, [currentPage]);

//   if (!data) {
//       return <div>Loading...</div>;
//     }

//   const formatDate = (dateStr) => {
//     const date = new Date(dateStr);
//     const options = { year: 'numeric', month: 'short', day: 'numeric' };
//     const formattedDateString = date.toLocaleDateString('en-US', options).replace(',', '');
//     return formattedDateString;
//   }
//   const cityformat = (obj) => {
//     let wounded = obj.wounded || 0;
//     let killed = obj.dead || 0;
//     let city = obj.city.name;
  
//     let message = `${city} `;
//     if (killed > 0) {
//       message += `${killed} killed `;
//     }
//     if (wounded > 0) {
//       message += `${wounded} injured `;
//     }
//     if (wounded === 0 && killed === 0) {
//       message += '';
//     }
//     return message
//   }
  
//   const getMedia = (source) => {
//     const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=))([\w-]{10,12})(?:[^\s&]*)/;
//     const embedUrls = [];
  
//     source.some((o) => {
//       if (o.hasOwnProperty('type') && youtubeRegex.test(o.link)) {
//         const videoId = o.link.match(youtubeRegex)[1];
//         const embedUrl = `https://www.youtube.com/embed/${videoId}`;
//         console.log('3mg33e code',embedUrl);
//         embedUrls.push(embedUrl);
//         return true;
//       }
//       return false;
//     });
  
//     if (embedUrls.length > 0) {
//       return {
//         source: {
//           url: embedUrls[0],
//           type: 'mp4',
//         },
//         type: 'VIDEO',
//         name: 'Pearl Harbor',
//       };
//     }
//     return null;
//   };

//   function handlePreviousPage() {
//     setCurrentPage(pagination.previous_page_number);
//   }

//   function handleNextPage() {
//       setCurrentPage(pagination.next_page_number);
//   }

 
//   const greet = (id) => {
//     return id
//   }  
//   const items2 = [
//     {
//       title: "May 1940",
//       cardTitle: "Dunkirk",
//       cardSubtitle:
//         "Men of the British Expeditionary Force (BEF) wade out to a destroyer during the evacuation from Dunkirk.",
//       cardDetailedText: ["paragraph1", "paragraph2"],
//       timelineContent: <div>Custom content</div>,
//     }
//   ]
  
//   const items = data.map(obj => {return{title: formatDate(obj.eventDate), media: getMedia(obj.source) , cardTitle: cityformat(obj), cardSubtitle: obj.title, cardDetailedText: obj.source.map(o => <Link>{o.title}{o.title}{o.title}</Link>) }})
//   return (
//     <div>
//       <h1 className="nasir">Timeline: Somalia</h1>
//       <div style={{ width: "100%", height: "70vh" }}>
//           <Chrono allowDynamicUpdate={false} items={items} onScrollEnd={() => setCurrentPage(pagination.next_page_number)} buttonTexts={{first: 'Jump to First',}} theme={{primary: "red", secondary: "yellow" }}  mode="VERTICAL_ALTERNATING" />
//       </div>
//       {/* <div>
//       {pagination.has_next && (
//                 <button onClick={handleNextPage}>next Page</button>
//             )}
//       </div> */}
      
//   </div>
//   )
// }

// export default Chronobak


import React, { useEffect, useState, useRef } from 'react'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import '../../timeline-component-style.css'
import axios from "axios";
import FacebookVideo from '../common/FacebookVideo';
import YouTubeVideo from '../common/YouTubeVideo'
import {getMedia, formatDate, cityformat} from '../helpers/helpers'


import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ExpandCircleDownRoundedIcon from '@mui/icons-material/ExpandCircleDownRounded';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import WorkIcon from '@mui/icons-material/Work';
// import SchoolIcon from '@mui/icons-material/School';
// import StarIcon from '@mui/icons-material/Star';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import ArrowCircleDownRoundedIcon from '@mui/icons-material/ArrowCircleDownRounded';
import { borderBottom, margin } from '@mui/system';



const ChronoTimeline = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setLoading(true)
    let url = `http://localhost:8000/api/timeline/?page=${currentPage}`
    axios
      .get(url)
      .then((response) => {
          setData([...data, ...response.data.data])
          setPagination(response.data.pagination)
          setLoading(false) 
      })
      .catch((error) => console.log(error.message))
  }, [currentPage]);

  if (!data) {
      return <div>Loading...</div>;
    }

  function handlePreviousPage() {
    setCurrentPage(pagination.previous_page_number);
  }
  const handleDash = (name) => {
    return name.toLowerCase().replace(' ', '-') 
  }
  function handleNextPage() {
      setCurrentPage(pagination.next_page_number);
  }

  return (
    <div className='timeline-container-div'>
      <h1 className="timelineheader">Timeline: Somalia</h1>

      <VerticalTimeline>
      {data.map(obj => {return(
        <VerticalTimelineElement key={obj.id}
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'ffffff' , color: 'rgb(33, 150, 243)' }}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
        date={<div>{formatDate(obj.eventDate)}</div>} 
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        icon={<WorkIcon />}
        >
        <h3 className="vertical-timeline-element-title city-and-count">
          <Link to={`/timeline/${handleDash(obj.city.region.name)}/${handleDash(obj.city.name)}`}><span className='title-tags'>{obj.city.name}</span></Link>
          <span className='title-tags'>{obj.city.region.name}</span>
          {cityformat(obj) && <span className='title-tags'>{cityformat(obj)}</span>}
          {getMedia(obj.source, obj.id) ?? ''}</h3>        
        <h4 className="vertical-timeline-element-subtitle">{obj.title}</h4>
        {obj.source.map(s =>
          <p key={s.url ? s.url : s.link}>
            <Link target="_blank" style={{textAlign: 'left', marginBottom: '150px'}} className={'timeline-link'} to={s.url? s.url : s.link}>{s.title} {<OpenInNewIcon style={{ fontSize: 16 }}/>}
            </Link>
          </p>
        )}
      </VerticalTimelineElement>)
        })}
      </VerticalTimeline>

      <div className='load-more'>
      {pagination.has_next && (
        <span className='timeline-load-more' onClick={()=> setCurrentPage(pagination.next_page_number)}>Load More...</span>
        // <ArrowCircleDownRoundedIcon className='pagedown' onClick={()=> setCurrentPage(pagination.next_page_number)} style={{ fontSize: 70, color:'#2096F3' }}/>
      )}
      </div>
    </div>
  )
}
export default ChronoTimeline