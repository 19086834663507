import React, { useEffect, useState } from 'react'
import axios from "axios";
// import { api } from '../utils'
// import {api} from '../../utils'
import { Chart } from "react-google-charts";
const YearlyChart = ({ data, loading }) => {
    // const [data, setData] = useState();
    const [DataFinal, setDataFinal] = useState([]);
    // const [data, setData] = useState(['mon', 'tue', 'wed']);

    useEffect(() => {
        if (data) {
          const transformedData = data.map(({ year, killed, injured }) => [
            year,
            killed,
            injured,
          ]);
      
          setDataFinal(transformedData);
        }
      }, [data]);
      

    if (!data) {
        return '';
      }

    console.log('google-data =>',data)
    if (data) {
        // console.log('gg', data.map(d => [d.year, d.killed, d.injured]))
        // console.log('sfasfa',[["Age", "Weight"], ...data])
        console.log('trasnformed', DataFinal)
    }
    const row = data.map(obj => [obj.year, obj.killed, obj.injured])
    const columns = [
        {
          type: 'string',
          label: 'Name'
        },
        {
          type: 'number',
          label: 'killed'
        },
        {
          type: 'number',
          label: 'injured'
        }
      ];
    console.log('roww',row)
    const options = {
        title: 'Yearly stats',
        colors: ['#DC3912', '#3366CC', '#FF9900', '#109618'],
        // other options
      };
  return (
    <div>
        {data ? 
    
    <Chart
        chartType="BarChart"
        data={[columns, ...row]}
        options={options}
        width="100%"
        height="400px"
        legendToggle
  />
 

  
  : null}
  </div>
  )
}

export default YearlyChart