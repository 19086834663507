import React, {useState} from 'react'
import { useParams } from 'react-router-dom';
import DynamicTimeline from '../common/DynamicTimeline';
// return `#ED753E`;
// } else if (obj.status === 'liberated') {
//   return '#4CB1CD';
const TerritoryStatus = () => {
    const [currentPage, setCurrentPage] = useState(1);
    // const { city, region } = useParams();
    // let sub = () =>{
    //     return <p>hello</p>
    // }
    const sub = () => {
        const squareStyle = {
          width: '60px',
          height: '20px',
          backgroundColor: '#ED753E',
          border: '1px solid #ED753E',
        };
        const squareStyle2 = {
            width: '60px',
            height: '30px',
            backgroundColor: '#4CB1CD',
            border: '1px solid #4CB1CD',
          };   
        return <div className='color-coding'><span className='occupied color-coding'>Occupied Territories</span><span className='liberated'>Liberated Territories</span></div>;
      };

  return (
    <div>
        {/* <BlueEmptySquare /> */}
        {/* CityTimeline {region} */}
        <DynamicTimeline title={'Territorial control '} url={`&territory=territory`} subtitle={sub} />
    </div>
  )
}

export default TerritoryStatus